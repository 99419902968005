import React from "react";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import useGlobal from "../state/useGlobal";
import BigNumber from "bignumber.js";
import classNames from "classnames";

export default function FakeCart({ schoolCode, showMobile = false }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { fakeCart } = globalState;
  const { items } = fakeCart;

  const cartItems = Object.keys(items).map((key) => {
    return {
      ...items[key],
    };
  });

  const total = cartItems.reduce((accumulator, { price, qty }) => {
    return new BigNumber(accumulator).plus(
      new BigNumber(price).multipliedBy(new BigNumber(qty))
    );
  }, 0);

  const totalQty = cartItems.reduce((accumulator, { qty }) => {
    return new BigNumber(accumulator).plus(new BigNumber(qty));
  }, 0);

  return (
    <div
      className={classNames({
        "md:w-48": true,
        "hidden md:flex": !showMobile,
        "md:flex": showMobile,
      })}
    >
      <div className="relative inline-block">
        <button
          type="button"
          onClick={() => globalActions.openFakeCartModal()}
          className="inline-flex items-center px-6 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm md:px-4 md:py-1 bg-celadon-green-600 hover:bg-celadon-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-celadon-green-500"
        >
          <ShoppingBagIcon
            className="w-6 h-6 -ml-1 md:w-8 md:h-8 md:mr-2"
            aria-hidden="true"
          />
          <span className="hidden mr-2 md:block">
            {new BigNumber(total).toFormat(2).toString()} €
          </span>
        </button>
        <div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 m-1 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-celadon-green-600 ring-2 ring-white">
          <span className="font-medium text-white">{totalQty.toString()}</span>
        </div>
      </div>
    </div>
  );
}
