import React, {Component} from "react";
import AppLayout from "../../components/AppLayout";
import Header from "../../components/Header";
import {withTranslation} from "react-i18next";
import {connect} from "../../state/useGlobal";
import {Redirect, withRouter} from "react-router";
import {Helmet} from "react-helmet";
import {STATUS_NOT_FOUND} from "../../state/groupOrder";
import Loading from "../../components/Loadingv2";
import ProductModal from "../../components/ProductModal";
import FakeCartModal from "../../components/FakeCartModal";
import CartSteps from "../../components/CartSteps";
import {STEP_UP_SELL_PRODUCT} from "../../constants/cartSteps";
import history from "../../routes/history";
import ProductsSections from "./ProductsSections";
import mixpanel from 'mixpanel-browser';

class AllInOneProductsScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
    };
  }

  componentDidMount() {
    const { globalActions, ga4 } = this.props;
    globalActions.setCheckout({
      currentStep: STEP_UP_SELL_PRODUCT,
    });
    this.loadGroupOrder();

    try {
      ga4.pageview('/calcuso-group-order-add-cart-product', '/calcuso-group-order-add-cart-product', 'Group Order | Add product to cart');
      const { schoolCode } = this.state;
      mixpanel.track_pageview({
        "page": "Group Order | Add product to cart",
        "school_code": schoolCode,
      })
    } catch (e) {
      console.log(e);
    }
  }

  loadGroupOrder = async () => {
    const { globalActions, globalState } = this.props;
    const { schoolCode } = this.state;

    // Verify School Code
    const { groupOrder } = globalState;
    const { school_code } = groupOrder;
    if (school_code && school_code !== schoolCode) {
      history.push(`/group-orders/${schoolCode.trim()}`);
    }

    await globalActions.loadGroupOrder(schoolCode);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { t, globalState } = this.props;
    const { schoolCode, isLoading } = this.state;
    const { groupOrder } = globalState;
    const { status } = groupOrder;

    return (
      <AppLayout
        contentBackgroundColor="bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6"
        textColor="text-gunmetal-black"
        border={false}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {status === STATUS_NOT_FOUND ? (
              <Redirect to="/" />
            ) : (
              <>
                <Helmet>
                  <title>
                    {schoolCode} | {groupOrder?.school?.name} |{" "}
                    {t("Group Order")}
                  </title>
                </Helmet>
                <Header
                  title={t("Group Order")}
                  subTitle={groupOrder?.school?.name}
                >
                  <CartSteps />
                </Header>

                <ProductsSections groupOrder={groupOrder} />
              </>
            )}
          </>
        )}
        <ProductModal />
        <FakeCartModal />
      </AppLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(AllInOneProductsScreen)));
