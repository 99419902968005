import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import CalculatorSection from "./Sections/CalculatorSection";
import SchoolbookSection from "./Sections/SchoolbookSection";
import StationerySection from "./Sections/StationerySection";
import UpSellSection from "./Sections/UpSellSection";
import {
    PRODUCT_TAB_CALCULATOR,
    PRODUCT_TAB_SCHOOLBOOK,
    PRODUCT_TAB_STATIONERY,
    PRODUCT_TAB_UP_SELL,
} from "../../constants/productTabs";
import NavigationTabs from "./NavigationTabs";
import {useTranslation} from "react-i18next";
import useGlobal from "../../state/useGlobal";

export default function ProductsSections({groupOrder}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const {student} = checkout;
    const {calculator, items, configs, up_sell} = groupOrder;
    const {t} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [isAllProductInViewport, setIsAllProductInViewport] = useState(false);

    const tabs = [
        {
            key: PRODUCT_TAB_CALCULATOR,
            name: t("Calculator"),
            component: CalculatorSection,
            show: !!calculator,
            props: {
                groupOrder,
            },
            refSection: useRef(null),
            refButton: useRef(null),
        },
        {
            key: PRODUCT_TAB_SCHOOLBOOK,
            name: t("School book"),
            component: SchoolbookSection,
            show: items.filter((item) => item.is_school_book).length > 0,
            props: {
                groupOrder,
            },
            refSection: useRef(null),
            refButton: useRef(null),
        },
        {
            key: PRODUCT_TAB_STATIONERY,
            name: t("Stationery"),
            component: StationerySection,
            show:
                items.filter((item) => !item.is_school_book && !item.is_calculator)
                    .length > 0,
            props: {
                groupOrder,
            },
            refSection: useRef(null),
            refButton: useRef(null),
        },
        {
            key: PRODUCT_TAB_UP_SELL,
            name: t("Up Sell"),
            component: UpSellSection,
            show: up_sell?.items?.length > 0,
            props: {
                groupOrder,
            },
            refSection: useRef(null),
            refButton: useRef(null),
        },
    ];

    const firstTab = tabs.find((tab) => tab.show);
    const [currentTab, setCurrentTab] = useState(firstTab?.key);

    const scrollDown = (ref) => {
        try {
            window.scrollTo({
            top: ref.current.offsetTop - 70,
            behavior: "smooth",
        });
        } catch (e) {
            // Do nothing if can found ref
        }
    };

    const scrollTo = (el) => {
        const elLeft = el.offsetLeft + el.offsetWidth;
        const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

        if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
            el.parentNode.scrollLeft = elLeft - elParentLeft + 30;
        } else {
            el.parentNode.scrollLeft = 0;
        }
    };

    const getOffset = (el) => {
        const elRect = el?.getBoundingClientRect();
        return elRect?.top;
    };

    const listenToScroll = () => {
        const heightToHide = getOffset(
            document.getElementById("stickyPlaceholder")
        );
        const windowScrollHeight =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (windowScrollHeight > heightToHide) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        // Highlight section
        tabs
            .filter((tab) => tab.refSection.current)
            .forEach((tab) => {
                const sectionTop = tab.refSection.current.offsetTop - 80;
                if (window.pageYOffset >= sectionTop) {
                    setCurrentTab(tab.key);
                    scrollTo(tab.refButton.current);
                } else {
                    //End of scroll
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        // Last product listing is view port
                        if (elementInViewport(tab.refSection.current)) {
                            setCurrentTab(tab.key);
                            scrollTo(tab.refButton.current);
                        }
                    }
                }
            });
    };
    const elementInViewport = (el) => {
        let top = el.offsetTop;
        let left = el.offsetLeft;
        let width = el.offsetWidth;
        let height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    };
    useEffect(() => {
        if (window.innerHeight === document.body.offsetHeight) {
            setIsAllProductInViewport(true);
        }
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="py-4 mx-auto">
            <div
                className={classNames({
                    "fixed inset-x-0 top-0 lg:border-b-2 lg:border-floral-white-800 lg:bg-white py-2": isVisible,
                    "py-4": !isVisible,
                    "mx-auto px-2 z-10": true,
                })}
            >
                <div className="max-w-2xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
                    <div
                        id="button-tabs"
                        className="flex self-center p-1.5 overflow-x-scroll bg-gray-100 lg:bg-white rounded-lg shadow md:overflow-hidden lg:shadow-none"
                    >
                        {tabs.map((tab) => {
                            return tab.show ? (
                                <button
                                    key={tab.key}
                                    ref={tab.refButton}
                                    onClick={() => {
                                        scrollDown(tab.refSection);
                                    }}
                                    type="button"
                                    className={classNames({
                                        "text-white bg-celadon-green-600 rounded-md shadow":
                                            tab.key === currentTab,
                                        " text-gunmetal-black-500": !(tab.key === currentTab),
                                        "ml-0.5 p-2 text-sm font-medium whitespace-nowrap sm:w-auto sm:px-8": true,
                                    })}
                                >
                                    {tab.name}
                                </button>
                            ) : (
                                ""
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="pb-20 bg-cal-primary-floral-white-E0DACC lg:bg-cal-primary-floral-white-FEF9EF ">
                <div className="max-w-2xl px-4 py-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
                    <div id="stickyPlaceholder" className="grid grid-cols-1 py-4 gap-y-8">
                        <div className="w-full p-4 ">
                            <h3 className="text-xl font-medium tracking-wide text-center text-gunmetal-black-500">
                                {configs?.message_on_calculator_tab
                                    ? configs.message_on_calculator_tab
                                    : t(
                                        "Great! Now add the products {{first_name}} needs for the lesson.",
                                        {
                                            first_name: student.first_name,
                                        }
                                    )}
                            </h3>
                        </div>
                    </div>
                    {tabs.map((tab) => {
                        const TabComponent = tab.component;
                        return (
                            <TabComponent
                                key={tab.key}
                                item={calculator}
                                name={tab.name}
                                refSection={tab.refSection}
                                {...tab.props}
                            />
                        );
                    })}
                </div>
            </div>
            <NavigationTabs
                tabs={tabs.filter((tab) => tab.show)}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                isAllProductInViewport={isAllProductInViewport}
            />
        </div>
    );
}
