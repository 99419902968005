import React from "react";
import globalHook from "use-global-hook";

import {
    initialState as groupOrder,
    setGroupOrder,
    loadGroupOrder,
    loadGroupOrderWithProduct,
    initGroupOrder,
    loadShippingMethods,
} from "./groupOrder";
import {initialState as store, setStore} from "./store";
import {
    initialState as checkout,
    setCheckout,
    initCheckout,
    initAddresses,
    loadPaymentMethods,
    loadPaymentMethodsAdyen
} from "./checkout";
import {
    initialState as fakeCart,
    setFakeCart,
    initFakeCart,
    addToCart,
    removeFromCart,
    minusFromCart,
    addOptionsFromCart,
    changeOptionsFromCart,
} from "./fakeCart";
import {
    initialState as productModal,
    setProductModal,
    openProductModal,
    closeProductModal,
} from "./productModal";
import {
    initialState as cartModal,
    setCartModal,
    openCartModal,
    closeCartModal,
    setCartDataModal,
} from "./cartModal";
import {
    initialState as fakeCartModal,
    setFakeCartModal,
    openFakeCartModal,
    closeFakeCartModal,
    setFakeCartDataModal,
} from "./fakeCartModal";
import {
    initialState as gravurModal,
    setGravurModal,
    openGravurModal,
    closeGravurModal,
    setGravurTextGravurModal,
} from "./gravurModal";
import {initialState as cart, setCart, loadCart} from "./cart";
import {
    initialState as confirmModal,
    setConfirmModal,
    openConfirmModal,
    closeConfirmModal,
} from "./confirmModal";
import {initialState as multiBancoResponse, setResponseForMultiBanco} from "./multiBanco";
const initialState = {
    groupOrder,
    store,
    checkout,
    productModal,
    cartModal,
    fakeCart,
    gravurModal,
    fakeCartModal,
    cart,
    confirmModal,
    multiBancoResponse,
};

const actions = {
    loadGroupOrder,
    setGroupOrder,
    setStore,
    setCheckout,
    initCheckout,
    setProductModal,
    openProductModal,
    closeProductModal,
    setCartModal,
    openCartModal,
    closeCartModal,
    setCartDataModal,
    loadGroupOrderWithProduct,
    initGroupOrder,
    setFakeCart,
    initFakeCart,
    addToCart,
    removeFromCart,
    minusFromCart,
    addOptionsFromCart,
    setGravurModal,
    openGravurModal,
    closeGravurModal,
    setFakeCartModal,
    openFakeCartModal,
    closeFakeCartModal,
    setFakeCartDataModal,
    setCart,
    loadCart,
    loadShippingMethods,
    initAddresses,
    loadPaymentMethods,
    loadPaymentMethodsAdyen,
    changeOptionsFromCart,
    setConfirmModal,
    openConfirmModal,
    closeConfirmModal,
    setGravurTextGravurModal,
    setResponseForMultiBanco,
};

const useGlobal = globalHook(React, initialState, actions);
export const connect = (Component) => {
    return (props) => {
        let [globalState, globalActions] = useGlobal();
        let _props = {...props, globalState, globalActions};
        return <Component {..._props} />;
    };
};

export default useGlobal;
