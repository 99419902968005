import React, { useState, useEffect } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
// import { TrashIcon } from "@heroicons/react/outline";
import useGlobal from "../../../state/useGlobal";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import toast from "react-hot-toast";
import {
  ATTRIBUTE_CODE_COLOR,
  ATTRIBUTE_CODE_GRAVIUR,
  ATTRIBUTE_CODE_IS_GRAVIUR,
  LIMIT_CART_CALCULATOR
} from "../../../constants/common";
import Color from "./Options/Color";
import Gravur from "./Options/Gravur";
import config from "../../../config";
const Image = ({ src, alt }) => {
  const [errored, setErrored] = useState(false);

  if (errored) {
    return (
      <svg
        className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
        preserveAspectRatio="none"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 200 200"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          d="M0 0l200 200M0 200L200 0"
        ></path>
      </svg>
    );
  } else {
    return (
      <img
        className="flex-shrink-0 object-contain w-full h-full mx-auto"
        src={`${config.cdn}${config.baseUrlMedia}${src}`}
        alt={alt}
        onError={setErrored}
      />
    );
  }
};

export default function FakeCartItemConf({ item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { groupOrder } = globalState;
  const { calculator } = groupOrder;
  const { product, price, qty, options } = item;
  
  // Find Simple
  const { product_simples } = product;
  const [simpleProduct, setSimpleProduct] = useState(null);
  useEffect(() => {
    const getImage = (lastValues) => {
      const product = product_simples.find((product_simple) => {
        let find = true;
        for (
          let index = 0;
          index < product_simple.product_custom_attributes.length;
          index++
        ) {
          const element = product_simple.product_custom_attributes[index];
          if (element.value !== lastValues[element.attribute_code]) {
            return false;
          }
        }

        return find;
      });

      setSimpleProduct(product);
    };

    getImage(options);
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const total = new BigNumber(price).multipliedBy(new BigNumber(qty));

  const handleOnRemove = async () => {
    globalActions.minusFromCart(item);
    toast(
      t(`"{{product_name}}" has been removed from your cart.`, {
        product_name: item.name,
      }),
      {
        id: item.id + "remove",
        icon: <MinusIcon className="w-8 h-8 text-fiery-rose-600" />,
      }
    );
  };

  // const handleOnRemoveAllItem = async () => {
  //   globalActions.removeFromCart(item);
  //   toast(
  //     t(`"{{product_name}}" has been removed from your cart.`, {
  //       product_name: item.name,
  //     }),
  //     {
  //       id: item.id + "removeAll",
  //       icon: <TrashIcon className="w-8 h-8 text-fiery-rose-600" />,
  //     }
  //   );
  // };

  const handleOnAdd = async () => {
    if (item?.id === calculator?.product_id) {
      if (item?.qty < LIMIT_CART_CALCULATOR) {
        globalActions.addToCart(item);
        toast.success(
          t(`"{{product_name}}" has been added to your cart.`, {
            product_name: item.name,
          }),
          {
            id: item.id + "add",
          }
        );
      }
    } else {
      globalActions.addToCart(item);
      toast.success(
        t(`"{{product_name}}" has been added to your cart.`, {
          product_name: item.name,
        }),
        {
          id: item.id + "add",
        }
      );
    }
  };

  return (
    <li className="flex py-6">
      <div className="flex-shrink-0 w-16 overflow-hidden md:w-24 md:h-24">
        <Image src={simpleProduct?.product_image} alt={simpleProduct?.name} />
      </div>

      <div className="flex flex-col flex-1 ml-4 space-y-4">
        <div>
          <h3 className="font-medium text-gray-900 overflow-ellipsis">
            {product.name}
          </h3>
          {options &&
            Object.entries(options).map(([key, value]) => {
              const option = product?.configurable_product_options.find(
                (option) => option.attribute_code === key
              );
              const attribute_option = option?.attribute_options.find(
                (attribute_option) => attribute_option.value_index === value
              );

              switch (key) {
                case ATTRIBUTE_CODE_COLOR:
                  return value ? (
                    <Color key={key} value={value} item={item} />
                  ) : (
                    ""
                  );
                case ATTRIBUTE_CODE_GRAVIUR:
                  return value ? <Gravur key={key} value={value} /> : "";
                case ATTRIBUTE_CODE_IS_GRAVIUR:
                  return "";
                default:
                  return (
                    <p key={key} className="mt-1 text-gunmetal-black-500">
                      {attribute_option?.label}
                    </p>
                  );
              }
            })}
        </div>
        <div className="flex items-end justify-between flex-1 text-sm">
          <div className="flex items-end space-x-4 ">
            <span className="relative z-0 inline-flex rounded-lg shadow-sm">
              <button
                type="button"
                onClick={handleOnRemove}
                className={classNames({
                  "relative inline-flex items-center px-3 py-2 text-sm font-medium border-2 rounded-l-lg border-celadon-green-500 text-celadon-green-500 hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                })}
              >
                <MinusIcon className="w-3 h-3" />
              </button>
              <button
                type="button"
                className="relative inline-flex items-center px-3 py-2 -ml-px text-xs font-medium border-t-2 border-b-2 border-1 border-celadon-green-500 text-gunmetal-black-500 focus:z-10 focus:outline-none "
              >
                {item.qty}
              </button>
              <button
                type="button"
                onClick={handleOnAdd}
                disabled={(item?.id === calculator?.product_id && item?.qty >= LIMIT_CART_CALCULATOR) ? true : false }
                className={classNames({
                  "relative disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed inline-flex items-center px-3 py-2 -ml-px text-sm font-medium border-2 rounded-r-lg border-celadon-green-500 text-celadon-green-500  hover:text-celadon-green-dark-500 focus:z-10 focus:outline-none ": true,
                })}
              >
                <PlusIcon className="w-3 h-3" />
              </button>
            </span>
            <p className="hidden text-base text-gray-900 md:block">
              x {new BigNumber(price).toFormat(2).toString()} €
            </p>
            {/* <button
              type="button"
              onClick={handleOnRemoveAllItem}
              className="inline-flex items-center p-1 text-fiery-rose-600 hover:text-fiery-rose-700"
            >
              <TrashIcon className="w-6 h-6" />
            </button> */}
          </div>

          <p className="text-base font-medium text-gray-900 ">
            {total.toFormat(2).toString()} €
          </p>
        </div>
      </div>
    </li>
  );
}
