import useGlobal from "../../../../state/useGlobal";
import {useTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import api from "../../../../services/api";
import {checkPaymentDetailsAdyen, checkPaymentStatusAdyen, getDefaultConfigAdyen} from "../adyenHelper";
import history from "../../../../routes/history";
import {HandleAPICartError} from "../../../../helpers";
import AdyenCheckout from "@adyen/adyen-web";
import Button from "../../../../components/Button";
import {Dialog, Transition} from "@headlessui/react";
import toast from "react-hot-toast";
import mbwaycss from "../../../../styles/mbway.css";
import PaymentFeeMessage from "../../PaymentFeeMessage";
export default function MBWayPayment() {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {groupOrder, checkout} = globalState;

    const {school_code} = groupOrder;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [adyenMBWay, setAdyenMBWay] = useState(null);
    const [adyenCheckout, setAdyenCheckout] = useState(null);
    const [open, setOpen] = useState(false);
    const [resultPaymentStatus, setResultPaymentStatus] = useState(null);
    const mbwayLoading = React.createRef();

    const _placeOrder = async () => {

        if (!adyenMBWay.isValid) {
            if (adyenMBWay.errors.phoneNumber === null) {
                toast.error(t('Please fill in your phone number'));
            }

            return;
        }
        setLoading(true);

        const {checkout} = globalState;
        const {quote_id, email} = checkout;

        try {
            const payload = {
                email: email,
                paymentMethod: {
                    method: "adyen_hpp",
                    additional_data: {
                        stateData: JSON.stringify(adyenMBWay.data),
                        brand_code: "mbway",
                    }
                },
            };

            let response = await api.post(
                `/guest-carts/${quote_id}/payment-information`,
                {},
                payload
            );

            let orderId = response.data;
            let responseStatus = await checkPaymentStatusAdyen(orderId);
            setResultPaymentStatus(responseStatus);
            let paymentData = responseStatus.action.paymentData;

            if (responseStatus.resultCode === 'Pending') {
                setOpen(true);
                let loop = true;

                try {
                    do {
                        let responseLoading = await checkPaymentDetailsAdyen(globalState, paymentData);

                        if (responseLoading.data.resultCode === 'authorised') {
                            globalActions.initCheckout();
                            globalActions.initGroupOrder();
                            globalActions.initFakeCart();
                            setOpen(false);
                            history.push(`/group-orders/${school_code.trim()}/checkout/done`);
                            return;
                        }

                        if (responseLoading.data.resultCode === 'refused') {
                            toast.error(t('Your payment failed, Please try again later.'));
                            setOpen(false);
                        }

                        await new Promise(resolve => setTimeout(resolve, 3000));
                    } while (loop);
                } catch (e) {
                    console.log(e);
                }
            }
        } catch (error) {
            setLoading(false)
            HandleAPICartError(error, globalState);
        }
    };

    const handleTransitionEnd = () => {
        adyenCheckout.createFromAction(resultPaymentStatus.action).mount(mbwayLoading.current);
    }

    useEffect(() => {
        const createCheckout = async () => {
            let adyenCheckout = await (new AdyenCheckout(getDefaultConfigAdyen(globalState, {
                showPayButton: false,
                onChange: (state, component) => {
                    setAdyenMBWay(state);
                },
            })));

            setAdyenCheckout(adyenCheckout);
            const mbwayComponent = adyenCheckout.create('mbway').mount('#mbway-container');
            setAdyenMBWay(mbwayComponent);
        }
        createCheckout();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="pt-4 space-x-8">
            <div id="mbway-container"></div>
            <div className="flex items-center justify-between space-x-8">
                <div>
                    <PaymentFeeMessage name={"MBWay"}/>
                </div>
                <Button
                    type="button"
                    onClick={_placeOrder}
                    color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                    size="xl"
                    loading={loading}
                >
                    <span>{t("Place your order")}</span>
                </Button>
            </div>
            <>
                <Transition appear show={open} onTransitionEnd={handleTransitionEnd} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => ({})}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25"/>
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        {/*<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"*/}
                                        {/*        onClick={this.closeModal}*/}
                                        {/*    >*/}
                                        {/*        <span className="sr-only">Close</span>*/}
                                        {/*        <XIcon className="h-6 w-6" aria-hidden="true" />*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                        <div className="mt-2">
                                            <div ref={mbwayLoading}></div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>
        </div>
    );
}
