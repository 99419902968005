import React from "react";
// import StripePayment from "./Payments/StripePayment";
import CashPayment from "./Payments/CashPayment";
// import { Link } from "react-router-dom";
import Button from "../../components/Button";
import useGlobal from "../../state/useGlobal";
// import SoftPayment from "./Payments/SoftPayment/SoftPayment";
import PaypalPlusPayment from "./Payments/PaypalPlusPayment/PaypalPlusPayment";
import PaypalExpressPayment from "./Payments/PaypalExpressPayment";
import { useTranslation } from "react-i18next";
// import MultibancoPayment from "./Payments/MultibancoPayment";
import CreditCard from "./AdyenPayments/CreditCard";
import SofortPayment from "./AdyenPayments/SofortPayment";
import MultiBancoPayment from "./AdyenPayments/MultiBancoPayment";
import {KlarnaPayLaterPayment, KlarnaPayOvertimePayment} from "./AdyenPayments/KlarnaPayment";
import MBWayPayment from "./AdyenPayments/MBWayPayment";
import {IDEAL_PAYMENT} from "../../constants/adyenPayment";
import IdealPayment from "./AdyenPayments/IdealPayment";
import EPSPayment from "./AdyenPayments/EPSPayment";
export default function PaymentMethod({ payment }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { groupOrder, store } = globalState;
  const { school_code } = groupOrder;
  const { t } = useTranslation();

  switch (payment) {
    case "calcuso_cash":
      return <CashPayment />;

    // case "stripe_payments":
    //   return <StripePayment />;
    //
    // case "stripe_payments_sofort":
    //   return <SoftPayment />;

    case "iways_paypalplus_payment":
      return <PaypalPlusPayment />;

    case "paypal_express":
      return <PaypalExpressPayment />;

    // case "stripe_payments_multibanco":
    //   return <MultibancoPayment />;

    case "adyen_cc":
      return <CreditCard globalState={globalState} globalActions={globalActions}/>;

    // case "adyen_multibanco":
    //   return <MultiBancoPayment />

    case "adyen_directEbanking":
      return <SofortPayment />

    case "adyen_klarna":
      return <KlarnaPayLaterPayment />

    case "adyen_klarna_account":
      return <KlarnaPayOvertimePayment />

    case "adyen_mbway":
      return <MBWayPayment />

    case "adyen_ideal":
      return <IdealPayment />

    case "adyen_eps":
      return <EPSPayment />

    default:
      return (
        <>
          <div className="my-2">{t("Not Support Yet.")}</div>
          <div className="flex items-center justify-end pt-8 space-x-8">
            {/* <Link
              to={`/group-orders/${school_code.trim()}/checkout/address`}
              className="font-medium cursor-pointer text-gunmetal-black-500"
            >
              {t("back")}
            </Link> */}
            <Button
              type="button"
              color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
              size="xl"
              disabled={true}
            >
              <span>{t("Place your order")}</span>
            </Button>
          </div>
        </>
      );
  }
}
