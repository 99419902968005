import React from "react";
import useGlobal from "../../../state/useGlobal";
import {
  InformationCircleIcon,
  PlusSmIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import BADGE_CONSTANTS from "../../../constants/badge";
import SecondaryButton from "../../SecondaryButton";
import ProductImage from "../../ProductImage";
import Badge from "../../Badge";
import toast from "react-hot-toast";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import EmptyIcon from "../../Icons/EmptyIcon";

export default function SimpleProduct({ item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { product_tags } = item;
  const { fakeCart } = globalState;
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];

  const handleAddToCart = () => {
    const payload = {
      id: item.product_id,
      name: item.name,
      sku: item.product_sku,
      type: item.product_type,
      price: item.product_price,
      qty: 1,
      product: item,
      options: {},
      created_at: new Date().getTime(),
    };
    globalActions.addToCart(payload);

    toast.success(
      t(`"{{product_name}}" has been added to your cart.`, {
        product_name: item.name,
      }),
      {
        id: item.id + "addNew",
      }
    );
  };

  const handleOnRemove = (removeItem) => {
    globalActions.minusFromCart(removeItem);
    toast(
      t(`"{{product_name}}" has been removed from your cart.`, {
        product_name: removeItem.name,
      }),
      {
        id: removeItem.id + "remove",
        icon: <MinusIcon className="w-8 h-8 text-fiery-rose-600" />,
      }
    );
  };

  const handleAdd = (addItem) => {
    globalActions.addToCart(addItem);
    toast.success(
      t(`"{{product_name}}" has been added to your cart.`, {
        product_name: addItem.name,
      }),
      {
        id: addItem.id + "add",
      }
    );
  };

  return (
    <div className="w-full p-4 bg-white rounded-lg ">
      <div className="grid grid-cols-3 gap-4 lg:grid-cols-5">
        <div className="flex flex-col space-y-2 md:space-y-0 md:space-x-2 md:flex-row">
          <div
            onClick={() => globalActions.openProductModal(item)}
          >
            <InformationCircleIcon className="w-8 h-8 rounded-full cursor-pointer bg-floral-white-dark-500 text-cal-primary-gunmetal-black-202833" />
          </div>
          <div
            className="flex items-center justify-center flex-1 cursor-pointer"
            onClick={() => globalActions.openProductModal(item)}
          >
            <ProductImage
              src={item.product_image}
              alt={item.name}
              size="h-24 w-24 md:h-28 md:w-28 lg:h-32 lg:w-32"
            />
          </div>
        </div>
        <div className="flex-1 col-span-2 lg:col-span-4 lg:space-y-4">
          <div className="flex flex-col items-start space-y-2">
            <div className="space-x-2 space-y-2">
              {product_tags.map((product_tag) => (
                <Badge
                  key={product_tag.tag_value}
                  color={BADGE_CONSTANTS.color[product_tag.tag_value]}
                  colorText={BADGE_CONSTANTS.colorText[product_tag.tag_value]}
                >
                  {BADGE_CONSTANTS.icon[product_tag.tag_value] ? (
                    BADGE_CONSTANTS.icon[product_tag.tag_value]
                  ) : (
                    <EmptyIcon className="h-6" />
                  )}
                  <span>{product_tag.tag_name}</span>
                </Badge>
              ))}
            </div>
            <h3 className="text-lg font-medium md:text-xl text-gunmetal-black-500">
              {item.name}
            </h3>
          </div>
          <div className="flex flex-col space-y-4 lg:space-x-4 lg:space-y-0 lg:flex-row">
            <div className="flex-col justify-between space-y-2 lg:w-4/6 xl:space-y-4">
              <div className="space-y-2 ">
                <div
                  className="text-sm prose text-left"
                  dangerouslySetInnerHTML={{ __html: item.short_description }}
                />
              </div>
            </div>
            <div className="flex-col items-end justify-end hidden space-y-2 lg:flex lg:w-2/6 xl:space-y-4 ">
              <div className="flex items-center justify-between w-full space-x-2 md:space-x-4 lg:justify-end">
                <div className="text-left lg:text-right ">
                  <h2 className="text-lg font-medium md:text-xl text-cal-primary-gunmetal-black-202833">
                    {selectedItem
                      ? new BigNumber(selectedItem.price).toFormat(2).toString()
                      : new BigNumber(item.product_price)
                          .toFormat(2)
                          .toString()}{" "}
                    €
                  </h2>
                  {item.tier_prices.map((tier_price) => (
                    <p
                      key={tier_price.price_id}
                      className="text-xs text-fiery-rose-500"
                    >
                      {t("from {{qty}} piece {{price}} €", {
                        qty: Math.floor(tier_price.price_qty),
                        price: new BigNumber(tier_price.price)
                          .toFormat(2)
                          .toString(),
                      })}
                    </p>
                  ))}
                </div>
                {selectedItem ? (
                  <span className="relative z-0 inline-flex rounded-lg shadow-sm">
                    <button
                      type="button"
                      onClick={() => handleOnRemove(selectedItem)}
                      className={classNames({
                        "relative inline-flex items-center px-4 py-2 text-sm font-medium border rounded-l-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                      })}
                    >
                      <MinusIcon className="w-4 h-4" />
                    </button>
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none "
                    >
                      {selectedItem.qty}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAdd(selectedItem)}
                      className={classNames({
                        "relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border rounded-r-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                      })}
                    >
                      <PlusIcon className="w-4 h-4" />
                    </button>
                  </span>
                ) : (
                  <SecondaryButton
                    type="buttom"
                    color="text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                    size="lg"
                    onClick={handleAddToCart}
                  >
                    <PlusSmIcon
                      className="w-5 h-5 mr-3 -ml-1 text-celadon-green-700"
                      aria-hidden="true"
                    />
                    <span>{t("add")}</span>
                  </SecondaryButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-end col-span-3 space-y-2 lg:hidden lg:w-1/2 xl:space-y-4 ">
              <div className="flex items-center justify-between w-full space-x-2 md:space-x-4 lg:justify-end">
                <div className="text-left lg:text-right ">
                  <h2 className="text-lg font-medium md:text-xl text-cal-primary-gunmetal-black-202833">
                    {selectedItem
                      ? new BigNumber(selectedItem.price).toFormat(2).toString()
                      : new BigNumber(item.product_price)
                          .toFormat(2)
                          .toString()}{" "}
                    €
                  </h2>
                  {item.tier_prices.map((tier_price) => (
                    <p
                      key={tier_price.price_id}
                      className="text-xs text-cal-primary-gunmetal-black-989898"
                    >
                      {t("from {{qty}} piece {{price}} €", {
                        qty: Math.floor(tier_price.price_qty),
                        price: new BigNumber(tier_price.price)
                          .toFormat(2)
                          .toString(),
                      })}
                    </p>
                  ))}
                </div>
                {selectedItem ? (
                  <span className="relative z-0 inline-flex rounded-lg shadow-sm">
                    <button
                      type="button"
                      onClick={() => handleOnRemove(selectedItem)}
                      className={classNames({
                        "relative inline-flex items-center px-4 py-2 text-sm font-medium border rounded-l-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                      })}
                    >
                      <MinusIcon className="w-4 h-4" />
                    </button>
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none "
                    >
                      {selectedItem.qty}
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAdd(selectedItem)}
                      className={classNames({
                        "relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium border rounded-r-lg border-celadon-green-300 text-cal-primary-floral-white-FFFEFC bg-celadon-green-500 hover:bg-celadon-green-dark-500 focus:z-10 focus:outline-none": true,
                      })}
                    >
                      <PlusIcon className="w-4 h-4" />
                    </button>
                  </span>
                ) : (
                  <SecondaryButton
                    type="buttom"
                    color="text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                    size="lg"
                    onClick={handleAddToCart}
                  >
                    <PlusSmIcon
                      className="w-5 h-5 mr-3 -ml-1 text-celadon-green-700"
                      aria-hidden="true"
                    />
                    <span>{t("add")}</span>
                  </SecondaryButton>
                )}
              </div>
            </div>
      </div>
    </div>
  );
}
