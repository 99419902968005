import React, { useEffect, useState } from "react";
import useGlobal from "../../../state/useGlobal";
import { useTranslation } from "react-i18next";
import ProductImage from "../../ProductImage";

export default function ImageContainer({ formik, item }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { fakeCart } = globalState;
  const { values } = formik;
  const { product_simples } = item;
  // get From Cart
  const { items } = fakeCart;
  const selectedItem = items[item.product_id];

  const [simpleProduct, setSimpleProduct] = useState(null);

  useEffect(() => {
    const getImage = (lastValues) => {
      const product = product_simples.find((product_simple) => {
        let find = true;
        for (
          let index = 0;
          index < product_simple.product_custom_attributes.length;
          index++
        ) {
          const element = product_simple.product_custom_attributes[index];
          if (element.value !== lastValues[element.attribute_code]) {
            return false;
          }
        }

        return find;
      });

      setSimpleProduct(product);
    };

    getImage(values);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProductImage
      src={simpleProduct ? simpleProduct.product_image : item.product_image}
      alt={item.name}
      size="h-32 w-32"
    />
  );
}
